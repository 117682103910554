<template>
<CRow>
    <CCol sm="12">
        <CCard style="min-height: 120px;">        
            <CCardHeader class="pt-2 pb-1">    
                <h4 class="pt-1 pb-0 float-left">Channel List</h4>  
            </CCardHeader>
            <CCardBody class="pt-2">
                <CRow v-if='isLoading'>  
                    <CCol lg="12" class="justify-content-center">
                        <CSpinner />
                    </CCol>
                </CRow>
                <CAlert :show="!isError && empty" color="info" class="mt-2 mb-0">
                No channels configured.<!--&nbsp;<CLink to="/bonuses/upload" class="strong text-primary">Click here to upload</CLink>.-->
                </CAlert>
                <CAlert :show="isError" color="danger" class="mt-2 mb-0">
                An unknown error occured. Please consider logging out and in again.
                </CAlert>
                <!-- @row-clicked="rowClicked"   -->
                <CDataTable
                    v-if="!empty && !isLoading"
                    :items="items"
                    :items-per-page="100"
                    :loading="isLoading"      
                    hover
                    addTableClasses="mb-0 pb-2 mt-3 channels-table"                      
                    :fields="[
                     { key:'name', label: 'Channel' },                                          
                     { key:'kind', label: 'Type', _style: 'width: 130px' },                     
                     { key:'orderDbKind', label: '', _style: 'width: 200px' },
                     { key:'productIdentifier', label: 'Product Identifier', _style: 'width: 200px' },
                     { key:'isActive', label: 'Is Active', _style: 'width: 100px' },
                     /*{ key:'title', label: 'Title' }, 
                     { key:'languages', label: 'Translations', _style: 'width: 300px' }, 
                     { key:'pageUrl', label:'Link', _style: 'width: 120px;'}*/
                    ]"    
                    @row-clicked="rowClicked"
                >
                    <template #isActive="{item}">
                        <td>
                            <CSwitch :checked="item.isActive" color="success" variant="3d" size="sm" :disabled="true" />
                        </td>
                    </template>
                    <template #name="{item}">
                        <td>
                            <CLink :to="`/channels/${item.name}`">{{item.displayName}} 
                            <!-- <CIcon name="cil-exit-to-app" size="sm" class="ml-1 mr-0 mb-1" /> -->
                            </CLink>
                        </td>
                    </template>
                    <template #kind="{item}">
                        <td>
                            <CBadge :color="item.kind=='Page' ? 'primary' : 'success'">{{ item.kind == 'Page' ? 'Content Page' : 'Order Database' }}</CBadge>
                        </td>
                    </template>
                    <template #orderDbKind="{item}">
                        <td>
                            <template v-if="item.kind=='OrderDb'">{{ item.orderDbKind}}</template>
                            <CLink v-if="item.kind=='Page' && !item.pageIsMissing" :to="`/pages/${item.pageName}`">{{item.pageName}}</CLink>
                            <span v-if="item.kind=='Page' && item.pageIsMissing" class="text-danger">{{item.pageName}} <CIcon name="cil-warning" size="sm" class="ml-1 mr-0 mb-1" color="danger" /></span>
                        </td>
                    </template>

                    <template #productIdentifier="{item}">
                        <td>
                            {{item.productIdentifier}}
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </CCard>
    </CCol>
</CRow>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';

export default {
    data() {
        return {
            isError: false
        }
    }, 
    computed: {
        ...mapGetters({ 
            items: 'products/channels',
            isLoading: 'products/loading',
            merchantId: 'merchantId' 
        }),
        empty() {
            return !this.isLoading && this.items && this.items.length == 0;
        }
    },
    methods: {
        ...mapActions({
            loadChannels: actionNames.LOAD_CHANNELS
        }),
        async load() {
            this.isError = false;
            try {
                if (this.merchantId != null) {
                    await this.loadChannels();
                }
            }
            catch (e)
            {
                console.debug("FAIL");
                console.debug(e);
                this.isError = true;
            }
        },
        rowClicked(item) {
            this.$router.push(`/channels/${item.name}`);
        }
    },
    created()
    {
        this.load();
    }
}
</script>

<style scoped>
.channels-table
{
    min-height: 120px;
}
</style>