var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',{staticStyle:{"min-height":"120px"}},[_c('CCardHeader',{staticClass:"pt-2 pb-1"},[_c('h4',{staticClass:"pt-1 pb-0 float-left"},[_vm._v("Channel List")])]),_c('CCardBody',{staticClass:"pt-2"},[(_vm.isLoading)?_c('CRow',[_c('CCol',{staticClass:"justify-content-center",attrs:{"lg":"12"}},[_c('CSpinner')],1)],1):_vm._e(),_c('CAlert',{staticClass:"mt-2 mb-0",attrs:{"show":!_vm.isError && _vm.empty,"color":"info"}},[_vm._v(" No channels configured.")]),_c('CAlert',{staticClass:"mt-2 mb-0",attrs:{"show":_vm.isError,"color":"danger"}},[_vm._v(" An unknown error occured. Please consider logging out and in again. ")]),(!_vm.empty && !_vm.isLoading)?_c('CDataTable',{attrs:{"items":_vm.items,"items-per-page":100,"loading":_vm.isLoading,"hover":"","addTableClasses":"mb-0 pb-2 mt-3 channels-table","fields":[
                     { key:'name', label: 'Channel' },                                          
                     { key:'kind', label: 'Type', _style: 'width: 130px' },                     
                     { key:'orderDbKind', label: '', _style: 'width: 200px' },
                     { key:'productIdentifier', label: 'Product Identifier', _style: 'width: 200px' },
                     { key:'isActive', label: 'Is Active', _style: 'width: 100px' } ]},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"isActive",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_c('CSwitch',{attrs:{"checked":item.isActive,"color":"success","variant":"3d","size":"sm","disabled":true}})],1)]}},{key:"name",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":("/channels/" + (item.name))}},[_vm._v(_vm._s(item.displayName)+" ")])],1)]}},{key:"kind",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":item.kind=='Page' ? 'primary' : 'success'}},[_vm._v(_vm._s(item.kind == 'Page' ? 'Content Page' : 'Order Database'))])],1)]}},{key:"orderDbKind",fn:function(ref){
                    var item = ref.item;
return [_c('td',[(item.kind=='OrderDb')?[_vm._v(_vm._s(item.orderDbKind))]:_vm._e(),(item.kind=='Page' && !item.pageIsMissing)?_c('CLink',{attrs:{"to":("/pages/" + (item.pageName))}},[_vm._v(_vm._s(item.pageName))]):_vm._e(),(item.kind=='Page' && item.pageIsMissing)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(item.pageName)+" "),_c('CIcon',{staticClass:"ml-1 mr-0 mb-1",attrs:{"name":"cil-warning","size":"sm","color":"danger"}})],1):_vm._e()],2)]}},{key:"productIdentifier",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.productIdentifier)+" ")])]}}],null,false,2968978383)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }